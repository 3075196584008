<ng-container *transloco="let t">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{config.header}}</h4>
    <button type="button" class="btn-close" [attr.aria-label]="t('common.close')" (click)="close()" *ngIf="!config.disableEscape"></button>
  </div>
  <div class="modal-body" style="overflow-x: auto" [innerHtml]="config.content | safeHtml">
  </div>
  <div class="modal-footer">
    <div *ngFor="let btn of config.buttons">
      <button type="button" class="btn btn-{{btn.type}}" (click)="clickButton(btn)">{{btn.text}}</button>
    </div>
  </div>

</ng-container>
